<template>
  <main v-if="access" class="cp">
    <cp-menu />
    <h1>Kino</h1>
    <section>
      <input type="text" v-model="filmId" placeholder="film id">
      <input type="button" value="Block" @click="addBlock('blocked')">
      <input type="button" value="Hide" @click="addBlock('hidden')">
    </section>
    <section>
      <strong>GET</strong>
      <input type="button" value="Albums" @click="getAlbums">
      <input type="button" value="Blocked" @click="getBlocked">
    </section>
    <div class="kino-header">
      <input type="text" v-model="albumId" placeholder="album id">
      <input type="button" value="post album" @click="postAlbum">
    </div>
    <section v-if="data.length">
      <h2>{{ dataType }}</h2>
      <ul class="ya-albums">
        <li v-for="item in data" :key="item._id">
          <a :href="`/catalog/${item.kinopoisk_id}`" target="_blank">
            <span class="blocked">{{ item.status }}</span>
              <img :src="filmPoster({ kinopoisk_id: item.kinopoisk_id })" target="_blank" alt="">
              <p>id {{ item.kinopoisk_id }}</p>
          </a>
          <div>
            <input type="button" value="Unblock" @click="removeBlock(item.kinopoisk_id)">
          </div>
        </li>
      </ul>
    </section>
    <section v-if="data.length && data[0].album_id">
      <iframe
        ref="albumiframe"
        frameborder="0"
        style="border:none;width:100%;height:600px;"
        width="100%"
        height="600"
        src=""
      />
      <input type="button" value="cls album" @click="$refs.albumiframe.src = ''">
    </section>
  </main>
</template>

<script>
import cpMenu from '~/components/cpMenu.vue'

export default {
  layout: 'clean',
  components: {
    cpMenu
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    key() {
      return localStorage.getItem('auth_token')
    }
  },
  data() {
    return {
      access: false,
      data: [],
      type: '',
      albumId: '',
      filmId: '',
      searchQuery: '',
      searchHints: [],
      dataType: '',
      filmInfo: null,
      filmParams: []
    }
  },
  mounted() {
    if (this.key) {
      this.$axios.get(`/api/admin?key=${this.key}`).then(({ data }) => {
        if (data.result) this.access = true
      })
    } else {
      console.log('Auth needed')
    }
  },
  methods: {
    clsCache(id = '') {
      this.$axios.delete(
        `/api/admin/clscache?key=${this.key}&kinopoisk_id=${id}`
      )
    },
    clsParams() {
      const imdbId = this.filmParams[0]
      this.filmParams = [imdbId]
    },
    async setParams() {
      const { data } = await this.$axios.post(
        `/api/admin/movieinfo?key=${this.key}&id=${this.filmId}`,
        this.filmParams
      )
      console.log(data)
      this.$store.commit('setError', 'Info added.')
      setTimeout(() => this.$store.commit('clearError'), 2000)
    },
    addParam(p) {
      this.filmParams.push(p)
    },
    delParam(p) {
      const index = this.filmParams.findIndex(el =>
        Object.keys(el)[0] === Object.keys(p)[0]
      )
      console.log('delParam index', index)
      this.filmParams.splice(index, 1)
    },
    async getFilmInfo(type) {
      const { data } = await this.$axios.get(
        `/api/admin/movieinfo?key=${this.key}&type=${type}&id=${this.filmId}`
      )
      if (data.error) {
        console.error('getFilmInfo', data.error)
      } else {
        console.log('getFilmInfo', data)
        this.filmInfo = data
        this.filmParams = []
        this.filmParams.push(data.find(el => el.kinopoisk_id))
        this.filmParams.push(data.find(el => el.imdb_id))
      }
    },
    copyId(id, token) {
      this.filmId = id
      this.searchQuery = ''
      this.filmInfo = null
      this.filmParams = []
    },
    trottled() {
      if (!this.searchQuery.trim()) return
      let oldValue = ''
      setTimeout(() => {
        if (
          oldValue === this.searchQuery &&
          this.searchQuery !== '' &&
          this.searchQuery.length > 2
        ) {
          this.getSearchHints()
        }
      }, 300)
      oldValue = this.searchQuery
    },
    async getSearchHints() {
      const { data } = await this.$axios.get(
        `/api/searchhints?type=db&value=${encodeURIComponent(
          this.searchQuery
        )}`
      )
      this.searchHints = data.sort((a, b) => (a.year > b.year ? -1 : 1))
    },
    setAlbumIframe(src) {
      this.$refs.albumiframe.src = `https://music.yandex.ru/iframe/#album/${src}/`
    },
    postAlbum() {
      this.$axios
        .post(
          `/api/admin/yamusic?key=${this.key}&kinopoisk_id=${
            this.filmId
          }&album_id=${this.albumId}`
        )
        .then(res => {
          console.log('postAlbum', this.albumId)
          this.albumId = ''
          this.getAlbums()
        })
    },
    addBlock(type) {
      if (!this.filmId) return
      this.$axios
        .post(
          `/api/admin/blocked?key=${this.key}&id=${this.filmId}&status=${type}`
        )
        .then(res => {
          console.log('addBlock', this.filmId)
          this.getBlocked()
        })
    },
    removeBlock(id, type) {
      this.$axios
        .delete(
          `/api/admin/blocked?key=${this.key}&id=${id}`
        )
        .then(res => {
          console.log('removeBlock', id)
          this.getBlocked(type)
        })
    },
    removeAlbum(id) {
      this.$axios
        .delete(`/api/admin/yamusic?key=${this.key}&kinopoisk_id=${id}`)
        .then(res => {
          console.log('removeAlbum', id)
          this.getAlbums()
        })
    },
    async getAlbums() {
      const { data } = await this.$axios.get(
        `/api/admin/yamusic?key=${this.key}`
      )
      this.dataType = 'Albums'
      if (data.length) {
        this.data = data.sort((a, b) => (a.date > b.date ? -1 : 1))
      } else {
        this.data = []
      }
    },
    async getBlocked() {
      const { data } = await this.$axios.get(
        `/api/admin/blocked?key=${this.key}`
      )
      this.dataType = 'Blocked'
      if (data.length) {
        this.data = data.sort((a, b) => (a.date > b.date ? -1 : 1))
      } else {
        this.data = []
      }
    }
  }
}
</script>

<style lang="stylus">
.tmdb
  div, ul, p
    margin 10px 0
  ul
    list-style none
    li
      display block
      margin 10px 0
      padding 10px
      &:hover
        background-color #f1f1f1
        color #000
        cursor pointer
      strong
        padding 5px
.kino-header
  margin 20px 0
.ya-albums
  list-style none
  li
    display inline-block
    width 150px
    position relative
    margin 0 20px 20px 0
    img
      width 100%
    .blocked
      background-color red
      color #fff
      padding 2px 4px
      border-radius 3px
      position absolute
      top 5px
      left 5px
      font-size 1.2rem
</style>
